
import './App.css'
import  LeakCheck  from './components/leak-check'

function App() {
  return (
    <LeakCheck/>
  )
}

export default App
