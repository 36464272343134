'use client'

import { useState, KeyboardEvent } from 'react'
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from "@/components/ui/card"
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import { Badge } from "@/components/ui/badge"
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion"
import { ShieldAlert, ShieldCheck, AlertCircle } from 'lucide-react'

export default function LeakCheck() {
  const [sample, setSample] = useState('')
  const [result, setResult] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const checkLeak = async () => {
    if (!sample) return
    setLoading(true)
    setError('')
    setResult(null)

    try {
      const response = await fetch(`https://corsproxy.io/?https://leakcheck.io/api/public?check=${encodeURIComponent(sample)}`)
      const data = await response.json()

      if (data.success) {
        setResult(data)
      } else {
        setError('No results found or an error occurred.')
      }
    } catch (err) {
      setError('An error occurred while checking the sample.')
    } finally {
      setLoading(false)
    }
  }

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      checkLeak()
    }
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 flex items-center justify-center p-4">
      <Card className="w-full max-w-md">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-center">Leak Check</CardTitle>
          <CardDescription className="text-center">
            Check if your email, username, or hash has been leaked
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <Input
              type="text"
              placeholder="Enter email, username, or hash"
              value={sample}
              onChange={(e) => setSample(e.target.value)}
              onKeyPress={handleKeyPress}
              className="w-full"
            />
            <Button
              onClick={checkLeak}
              disabled={loading || !sample}
              className="w-full"
            >
              {loading ? 'Checking...' : 'Check for Leaks'}
            </Button>
          </div>

          {error && (
            <Alert variant="destructive" className="mt-4">
              <AlertCircle className="h-4 w-4" />
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          {result && (
            <div className="mt-4 space-y-4">
              <Alert variant={result.found > 0 ? "destructive" : "default"}>
                {result.found > 0 ? (
                  <ShieldAlert className="h-4 w-4" />
                ) : (
                  <ShieldCheck className="h-4 w-4" />
                )}
                <AlertTitle>{result.found > 0 ? 'Leak Detected' : 'No Leaks Found'}</AlertTitle>
                <AlertDescription>
                  {result.found > 0
                    ? `Your information was found in ${result.found} leak(s).`
                    : 'Your information appears to be safe.'}
                </AlertDescription>
              </Alert>

              {result.found > 0 && (
                <Accordion type="single" collapsible className="w-full">
                  <AccordionItem value="item-1">
                    <AccordionTrigger>View Leak Details</AccordionTrigger>
                    <AccordionContent>
                      <div className="space-y-4">
                        <div>
                          <h3 className="font-semibold mb-2">Leaked Fields:</h3>
                          <div className="flex flex-wrap gap-2">
                            {result.fields.map((field: string, index: number) => (
                              <Badge key={index} variant="secondary">{field}</Badge>
                            ))}
                          </div>
                        </div>
                        <div>
                          <h3 className="font-semibold mb-2">Leak Sources:</h3>
                          <ul className="space-y-2">
                            {result.sources.map((source: { name: string, date: string }, index: number) => (
                              <li key={index} className="flex justify-between items-center">
                                <span>{source.name}</span>
                                <Badge variant="outline">{source.date}</Badge>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              )}
            </div>
          )}
        </CardContent>
        <CardFooter className="text-center text-sm text-gray-500">
          Powered by LeakCheck.io API (via CORS proxy)
        </CardFooter>
      </Card>
    </div>
  )
}